<template>
  <s-dialog
    :visible="show"
    class="page-login__signupEmailValidate"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="handlClose"
  >
    <p>{{ langText.SHEIN_KEY_PWA_18955 }}</p>
    <div class="input-area input-area-email">
      <label for="">{{ langText.SHEIN_KEY_PWA_14945 }}:</label>
      <div class="input-filed">
        <input
          class="normal-input"
          type="text"
          :value="opts.email"
          :disabled="true"
          :readonly="true"
        />
      </div>
    </div>
    <div
      class="input-area"
      :class="codeTip.show ? 'error' : ''"
    >
      <label for="registerPhoneCode">{{ langText.SHEIN_KEY_PWA_17724 }}</label>
      <div class="input-filed">
        <input
          id="registerPhoneCode"
          v-model="code"
          type="text"
          :placeholder="langText.SHEIN_KEY_PWA_18691"
          @input="handleCodeInputChange"
        />
        <s-button
          v-show="codeDownTime <= 0"
          class="code-btn"
          :type="['primary', 'H48PX']"
          @click="handleSendCode()"
        >
          {{ codeIsSended ? langText.SHEIN_KEY_PWA_14897 : langText.SHEIN_KEY_PWA_17205 }}
        </s-button>
        <s-button
          v-show="codeDownTime > 0"
          class="code-btn"
          disabled
          :type="['primary', 'H48PX']"
        >
          {{ codeDownTime }}S
        </s-button>
      </div>
      <p
        v-show="codeTip.show"
        class="tip"
      >
        {{ codeTip.txt }}
      </p>
    </div>
    <s-button
      :type="['primary']"
      style="margin-bottom: 12px;"
      width="100%"
      @click="handleConfirm"
    >
      {{ langText.SHEIN_KEY_PWA_16462 }}
    </s-button>
  </s-dialog>
</template>

<script>
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { defineComponent } from 'vue'
import { thirdLoginCodeSend } from '../../service'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { geetestChallenge } from 'public/src/pages/common/RiskCommon/common/geetestChallenge.js'
import analysisIndex from '../../analysis/index'

const { loginPageFrom } = analysisIndex

daEventCenter.addSubscriber({ modulecode: '2-16' })

export default defineComponent({
  name: 'LoginSignupEmailValidate',
  components: {
    SDialog,
    SButton,
  },
  props: {
    show: { type: Boolean, default: false },
    opts: { type: Object, default: () => ({}) },
    langText: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      code: '',
      codeTip: { show: false, txt: '' },
      codeDownTime: 0,
      codeIsSended: false, // 是否已经发送过验证
    }
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.handleSendCode()
          daEventCenter.triggerNotice({ daId: '2-16-1', extraData: { scene: 'register', login_from: loginPageFrom() } })
        }
      }, immediate: true
    }
  },
  methods: {
    handleCodeInputChange () {
      this.showInputTips(this.codeTip, '', false)
    },
    showInputTips (tip, msg, show = true) {
      tip.show = show
      tip.txt = msg
    },
    handleSendCode () {
      const opts = this.opts
      const params = {
        alias: opts.email,
        alias_type: 1,
        scene: 'register_email_verify',
        third_party_type: 7
      }
      this.codeSend(params)
    },
    async codeSend (params) {   
      this.isCodeSending = true
      // 极验验证
      const res = await geetestChallenge(thirdLoginCodeSend, params, 'send_message')
      if (!res) {
        return
      }
      const { apiResData = {}, status } = res
      if (status == 'error') {
        this.isCodeSending = false
        SToast(this.langText.SHEIN_KEY_PWA_17551)
        return
      }
      if (status == 'close') {
        this.isCodeSending = false
        return
      }
      const response = apiResData || {}
      const { code, tips } = response
      this.isCodeSending = false

      daEventCenter.triggerNotice({ daId: '2-16-3', extraData: { 
        scene: 'register',
        result: code == 0 ? 0 : 1,
        from: this.codeIsSended ? 0 : 1 
      } })

      if (code == 0) {
        this.codeIsSended = true
        this.startDownTime(60)
        return
      }
      SToast(tips || this.langText.SHEIN_KEY_PWA_14899)
    },
    startDownTime (time) {
      const n = time <= 0 ? 0 : time
      this.codeDownTime = n
      if (n <= 0) return clearTimeout(this.downtimer)
      this.downtimer = setTimeout(() => {
        this.startDownTime(n - 1)
      }, 1000)
    },
    clearInfo () {
      this.codeTip = ''
      this.code = ''
      this.codeIsSended = false
      this.codeDownTime = 0
    },
    handlClose () {
      this.clearInfo()
      this.$emit('close')
      daEventCenter.triggerNotice({ daId: '2-16-2', extraData: { scene: 'register' } })
    },
    handleConfirm () {
      if (this.code == '') {
        return
      }
      this.$emit('close')
      this.opts.cb(this.code)
    }
  },
})
</script>

<style lang="less">
.page-login {
    &__signupEmailValidate {
        p {
            text-align: center;
            color: @sui_color_gray_dark1;
            font-size: 16px;
            font-weight: bold;
            padding-bottom: 16px;
        }
        .input-area {
            position: relative;
            margin-bottom: 16.5px;
            border-bottom: 1px solid #e5e5e5;
        }
        .input-area.error {
            border-bottom-color: #BF4123;
        }
         .input-filed {
            display: flex;
            input {
              padding: 4px 0 17.5px 0;
              flex: 1;
              border: 0;
              .font-dpr(28px);
            }
        }
        label {
            .font-dpr(24px);
            color: #767676;
            line-height: 1;
        }
        input {
            border: 0;
            width: 100%;
            display: flex;
        }
        input::-webkit-input-placeholder {
            color: #959595;
            .font-dpr(28px);
        }
        .code-btn, .icon-see-normal {
            position: absolute;
            top: 50%;
            .right(0);
            transform: translateY(-50%);
        }
    }
}
</style>
